var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Drawer",
    {
      attrs: {
        title: _vm.isAgain ? "重新投保" : _vm.batch ? "批量投保" : "投保",
        visible: _vm.visible,
        size: "small",
        "z-index": 2,
      },
      on: { close: _vm.onClose, onSubmit: _vm.ok },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "form_box" },
          [
            !_vm.batch
              ? _c("UserInfo", { attrs: { "user-info": _vm.userInfo } })
              : _c("div", { staticClass: "userList" }, [
                  _c("p", [_vm._v("适用员工")]),
                  _c(
                    "div",
                    {
                      staticClass: "per_list",
                      style: !_vm.flag ? _vm.avatarClassDefault : "",
                    },
                    [
                      _vm._l(_vm.staff, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "per_list_avatar" },
                          [
                            _c("CbAvatar", {
                              attrs: {
                                "on-job-id": item.onJobId,
                                "avater-name": item.staffName,
                                size: "24",
                                "default-name-length": 1,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _vm.staff.length > 8
                        ? _c(
                            "div",
                            {
                              staticClass: "downUp",
                              on: {
                                click: function ($event) {
                                  _vm.flag = !_vm.flag
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "iconpark-icon",
                                  style: !_vm.flag
                                    ? "transform: rotate(270deg)"
                                    : "transform: rotate(90deg)",
                                },
                                [_c("use", { attrs: { href: "#left" } })]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
            _vm.visible
              ? _c(
                  "div",
                  { staticClass: "setForm" },
                  [
                    _c("AddFormVue", {
                      ref: "socail",
                      attrs: {
                        title: "社保",
                        payment: _vm.payment,
                        "is-again": _vm.isAgain,
                        "insure-classify": "social",
                        "form-data": _vm.insureData.social,
                      },
                      on: { setPayment: _vm.setPayment },
                    }),
                    _c("AddFormVue", {
                      ref: "medical",
                      attrs: {
                        title: "医保",
                        payment: _vm.payment,
                        "is-again": _vm.isAgain,
                        "insure-classify": "medical",
                        "form-data": _vm.insureData.medical,
                      },
                      on: { setPayment: _vm.setPayment },
                    }),
                    _c("AddFormVue", {
                      ref: "housing",
                      attrs: {
                        title: "公积金",
                        batch: _vm.batch,
                        payment: _vm.payment,
                        "is-again": _vm.isAgain,
                        "insure-classify": "housing",
                        "form-data": _vm.insureData.housing,
                      },
                      on: { setPayment: _vm.setPayment },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }